import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const akcio = () => (
  <Layout>
    <SEO
      title="Akcióink"
      description="Egyénre szabott optika Zugló szívében"
    />

<div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">




<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Állandó Akcióink:</h3>
<ul className="maintext2 mt-2 left2 text-left">
<li>2 ugyanolyan dioptriájú szemüveg vásárlása esetén a második keretbe ajándék szemüveglencsét adunk. (+-3,0D-ig, alap tükröződés és keményréteggel) </li>
<li>Fél éven belül a kontaktlencse kontroll vizsgálat díjtalan</li>


</ul>




</div></div>

    </div></div>
  </Layout>
);

export default akcio;
